<template>
  <div id="app">
    <!-- <div v-html="statistics"></div> -->
    <img
      class="imageView"
      src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/quick_estimate_instructions_20210706.png"
      alt
    />
  </div>
</template>
<script type="text/javascript" src='https://c.cnzz.com/core.php?web_id=1281102051&t=z' charset="utf-8">
</script>
<script>
export default {
  // 组件名称
  name: "demo",
  data() {
    return {
      // statistics: "",
    };
  },
  created() {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://s4.cnzz.com/z_stat.php?id=1281102051&web_id=1281102051";
    document.body.appendChild(s);
  },
  methods: {},
  mounted() {
    // let str = unescape(
    //   "%3Cspan id='cnzz_stat_icon_1281102051'%3E%3C/span%3E%3Cscript src='https://s4.cnzz.com/z_stat.php%3Fid%3D1281102051' type='text/javascript'%3E%3C/script%3E"
    // );
    // this.statistics = str;
  },
};
</script> 

<style scoped>
.imageView {
  width: 100%;
}
</style>
